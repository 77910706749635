import { InjectionToken } from "@angular/core";

export const APP_CONFIG = new InjectionToken<IAppConfig>("APP_CONFIG");

export interface IAppConfig {
  AzureAdB2C: IAzureB2Config;
  AppInsightInstrumentationKey: string;
  Values: IAppValues;
  ApiEndpoint: string;
  DefaultAccessTokenScopes: string;
  RetryOptions: RetryOptions;
}

export interface IAzureB2Config {
  Authority: string;
  ClientId: string;
  SignUpSignInFlow: string;
  AuthorityDomain: string;
}

export interface IAppValues {
  AuthorizedUrls: string[];
  NotAuthorizedUrls: string[];
  Scopes: string[];
  ApiVersion: string;
}

export interface RetryOptions {
  maxRetryAttempts: number;
  retryDelay: number;
  retryOnStatusCodes: number[];
}
