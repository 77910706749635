import { InternalServerErrorComponent } from './components/internal-server-error/internal-server-error.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';

@NgModule({
  declarations: [
    InternalServerErrorComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  exports: [
    HttpClientModule,
    InternalServerErrorComponent,
    NotFoundComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('Core module already imported');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      providers: [
      ],
      ngModule: CoreModule
    };
  }
}
