import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '@stagescherm/core/services';
import { isNullOrUndefined } from 'is-what';
import { MenuItem } from 'primeng/api/menuitem';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-horizontal-page',
  templateUrl: './horizontal-page.component.html',
  styleUrls: ['./horizontal-page.component.scss']
})
export class HorizontalPageComponent implements OnInit {
  public menuItems: MenuItem[] =[];
  public home: MenuItem = { routerLink: '/', icon: 'pi pi-home'};
  public isLoading: Observable<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
  ) { 
  }

  public ngOnInit(): void {
    this.createBreadcrumbs(this.activatedRoute.root, '', this.menuItems);
    this.isLoading = this.loadingService.isLoading$;
  }

  private createBreadcrumbs(route: ActivatedRoute, routerLink: string = '', breadcrumbs: MenuItem[] = []): void {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        routerLink += `/${routeURL}`;
      }

      const label = child.snapshot.data['title'];
      if (!isNullOrUndefined(label)) {
        breadcrumbs.push({label, routerLink});
      }

      this.createBreadcrumbs(child, routerLink, breadcrumbs);
    }
  }
}
