<app-horizontal-page>
  <app-horizontal-page-content>
    <h1>Oeps...</h1>
    <p>
      Er heeft zich een onverwachte fout voorgedaan. Mocht het probleem zich
      nogmaals voordoen neem dan contact met de servicedesk op.
    </p>
    <p *ngIf="id"><b>Foutcode: </b><i [innerText]="id"></i></p>
    <p-button
      label="Ga terug"
      styleClass="p-button-raised"
      (onClick)="back()"
    ></p-button>
  </app-horizontal-page-content>
</app-horizontal-page>
