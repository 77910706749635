import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ErrorHandlerService } from "@stagescherm/core/services/error-handler/error-handler.service";
import {
  Observable,
  catchError,
  delay,
  of,
  retry,
  tap,
  throwError,
} from "rxjs";
import { APP_CONFIG, IAppConfig, RetryOptions } from "src/app/app.config";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private retryRequestOptions: RetryOptions = {
    maxRetryAttempts: 3,
    retryDelay: 300, //milliseconds,
    retryOnStatusCodes: [502, 503, 504],
  };

  private retryOptionsConfigured = false;

  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) {
    if (!this.retryOptionsConfigured && this.appConfig.RetryOptions) {
      this.retryRequestOptions = appConfig.RetryOptions;
      this.retryOptionsConfigured = true;
    }
  }

  /**
   * Intercept the Http request and response and present a Toast
   *
   * @param req The request send to the API
   * @param next The next interceptor in the chain.
   * @returns Observable HttpEvent which is the HttpResponse
   */
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(this.retryPipe());
  }

  private retryPipe<T>() {
    return (source: Observable<T>) =>
      source.pipe(
        tap({
          error: (error: HttpErrorResponse) => {
            if (error.status === 500) {
              void this.router.navigate(["/500"]);
            }
          },
        }),
        retry({
          count: this.retryRequestOptions.maxRetryAttempts,
          delay: (error: HttpErrorResponse, retryId: number) => {
            if (
              this.retryRequestOptions.retryOnStatusCodes.includes(error.status)
            ) {
              return this.retryError(
                error,
                retryId,
                this.retryRequestOptions.retryDelay
              );
            } else {
              return throwError(() => error);
            }
          },
        }),
        catchError((error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error.error);
          return throwError(() => error.error);
        })
      );
  }

  private retryError(
    error: HttpErrorResponse,
    retryId: number,
    retryDelay: number
  ) {
    return of(error).pipe(delay(retryDelay * retryId));
  }
}
