import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    template: ''
})
export abstract class BaseComponent implements OnDestroy {
    public takeUntil$: Subject<void> = new Subject<void>();

    public ngOnDestroy(): void {
        this.takeUntil$.next();
        this.takeUntil$.complete();
    }
}