import { APP_CONFIG, IAppConfig } from './app.config';
import { MSALInstanceFactory } from './auth-config';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService
  } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(config: IAppConfig): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: config.Values.Scopes
    },
    loginFailedRoute: '/login-failed'
  };
}

export function MSALInterceptorConfigFactory(config: IAppConfig): MsalInterceptorConfiguration {
  var protectedResourceMap = new Map<string, Array<string> | null>();
  config.Values.NotAuthorizedUrls.forEach((url) => {
    protectedResourceMap.set(url, null);
  });
  config.Values.AuthorizedUrls.forEach((url) => {
    protectedResourceMap.set(url, config.Values.Scopes);
  });

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

@NgModule({
  declarations: [],
  imports: [
    MsalModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [APP_CONFIG]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [APP_CONFIG]
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [APP_CONFIG]
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ]
})
export class AuthConfigModule { }
