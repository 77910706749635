import { ErrorHandler, Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { isErrorReponseType } from '../../models/error-response';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  private readonly maxMessages = 5;
  private readonly messages: Message[] = [];

  constructor(private readonly messageService: MessageService) {}

  /**
   * Handle an error and display it as a message
   * @param error
   */
  public handleError(error: any): void {
    if(!error) return;
    const messages = this.extractMessages(error);
    messages.forEach((message) => this.updateMessagesList(message));
  }

  /**
   * Extracts error messages based on the type of error
   * @param error
   * @returns array of Message objects
   */
  private extractMessages(error: any): Message[] {
    if (isErrorReponseType(error)) {
      return error.errors.map((err) => this.createMessage(err.message));
    }
    
    if (typeof error === 'string' || error instanceof String) {
      return [this.createMessage(error as string)];
    }

    if (error?.message)
      return [this.createMessage((error?.message as string) || 'Unknown error')];

    return [];
  }

  /**
   * Creates a PrimeNG Message object
   * @param detail - the detail of the message
   * @returns a Message object
   */
  private createMessage(detail: string): Message {
    return {
      key: 'error',
      closable: true,
      sticky: true,
      severity: 'error',
      detail,
    } as Message;
  }

  /**
   * Updates the message list and ensures the max message limit is enforced
   * @param message - the message to add
   */
  private updateMessagesList(message: Message): void {
    this.messages.unshift(message);

    if (this.messages.length > this.maxMessages) {
      this.messages.pop();
      this.messageService.clear();
      this.messageService.addAll(this.messages);
    } else {
      this.messageService.add(message);
    }
  }
}
