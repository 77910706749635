import { APP_CONFIG, IAppConfig } from "./app.config";
import { SignalrMessageTypes } from "./models/signalr-message-types";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from "@azure/msal-angular";
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from "@azure/msal-browser";
import * as signalR from "@microsoft/signalr";
import { MessageService } from "primeng/api";
import { filter, Subject, takeUntil } from "rxjs";
import { RealtimeService } from "./core/services";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  private signalRConnection?: signalR.HubConnection;

  public loginDisplay: boolean = false;
  public username?: string;
  public isIframe = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private realtimeService: RealtimeService,
    private messageService: MessageService
  ) {}

  public ngOnInit(): void {
    this.isIframe = this.checkIfIframe();
    this.subscribeToMsalEvents();
  }

  public ngOnDestroy(): void {
    this.cleanUp();
  }

  public login(): void {
    const authRequest = this.msalGuardConfig.authRequest || { scopes: this.appConfig.Values.Scopes };
    this.authService.loginRedirect(authRequest as RedirectRequest);
  }

  public async logout(): Promise<void> {
    await this.realtimeService.stopConnection();
    this.authService.logoutRedirect()
  }

  private checkIfIframe(): boolean {
    return window !== window.parent && !window.opener;
  }

  private subscribeToMsalEvents(): void {
    this.MsalSubscription();
    this.MsalInProgressSubscription();
  }

  private setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;

    if (this.loginDisplay) {
      this.setUsername();
    }
  }

  private setUsername(): void {
    const account = this.authService.instance.getActiveAccount();

    if (account) {
      this.username = account.name;
    }
  }

  private async checkAndSetActiveAccount(): Promise<void> {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }

    this.setLoginDisplay();
    await this.setupSignalR();
  }

  private MsalInProgressSubscription() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  private MsalSubscription() {
    this.authService.instance.enableAccountStorageEvents(); // This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });
  }

  private async setupSignalR(): Promise<void> {
    try {
      this.signalRConnection = await this.realtimeService.buildConnection();
      if (!this.signalRConnection) {
        return;
      }

      this.signalRConnection.on(
        SignalrMessageTypes.ValidationComplete,
        (message: string) => {
          this.messageService.add({
            severity: "info",
            summary: "Stagevalidatie",
            detail: message,
          });
        }
      );
    } catch (error) {
      console.trace(`Failed to setup SignalR: ${error}`);
    }
  }

  private cleanUp(): void {
    this._destroying$.next();
    this._destroying$.complete();
    this.signalRConnection?.stop();
  }
}