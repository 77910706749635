import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-error-component',
  templateUrl: './internal-server-error.component.html',
})
export class InternalServerErrorComponent implements OnInit {
  public id: string | undefined = undefined;

  constructor(
    private readonly location: Location,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      (params: Params) => (this.id = params['id'])
    );
  }

  back(): void {
    this.location.back();
  }
}
