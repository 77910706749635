export type CustomSettings = {
  [key: string]: boolean;
};

export type DefaultSettings = {
  stageAanlevering: boolean;
  applicatieGesloten: boolean;
};

export type ApplicationSettings = CustomSettings & DefaultSettings;

//export a type check function for the DefaultSettings if there are missing properties on the given object
export function hasAllDefaultSettings(obj: any): obj is DefaultSettings {
  return obj.stageAanlevering !== undefined;
}
