import {
  HorizontalPageComponent,
  HorizontalPageContentComponent,
  HorizontalPageHeaderComponent,
  HorizontalPageMenuComponent
  } from './components/horizontal-page';
import { PageMenuItemComponent } from './components/page-menu-item/page-menu-item.component';
import { AuthorizedDirective } from './directives/authorized.directive';
import { PrimeNgModule } from './primeng/primeng.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

const importExportModules = [PrimeNgModule];
const importExportComponents = [
  PageMenuItemComponent,
  HorizontalPageComponent,
  HorizontalPageHeaderComponent,
  HorizontalPageContentComponent,
  HorizontalPageMenuComponent,
  AuthorizedDirective
];

@NgModule({
  declarations: [
    PageMenuItemComponent,
    HorizontalPageComponent,
    HorizontalPageHeaderComponent,
    HorizontalPageContentComponent,
    HorizontalPageMenuComponent,
    AuthorizedDirective
  ],
  imports: [CommonModule, ...importExportModules],
  exports: [...importExportModules, ...importExportComponents],
})
export class SharedModule {}
