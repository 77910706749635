import { Inject, Injectable, Optional } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import * as signalR from '@microsoft/signalr';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: "root"
})
export class RealtimeService {
    private baseUrl: string;
    private connection?: signalR.HubConnection;

    constructor(
        @Optional() @Inject(APP_CONFIG) private appConfig: IAppConfig,
        private authService: MsalService,
    ) {
        if (environment.production) {
            this.baseUrl = `${this.appConfig.ApiEndpoint}/integratie/${this.appConfig.Values.ApiVersion}`;
        } else {
            this.baseUrl = `${this.appConfig.ApiEndpoint}/${this.appConfig.Values.ApiVersion}`;
        }
    }

    /**
     * Build the SignalR Hub connection with the provided token
     * @param token Access token to authenticate the SignalR connection
     * @returns New SignalR Hub connection if it doesn't exist, otherwise the existing connection
     */
    public async buildConnection(): Promise<signalR.HubConnection | undefined> {
        if (this.connection) {
            return this.connection;
        }

        const activeAccount = this.authService.instance.getActiveAccount();
        if (activeAccount) {
            try {
                const response = await this.authService.instance.acquireTokenSilent({
                    account: activeAccount,
                    scopes: []
                });

                this.connection = new signalR.HubConnectionBuilder()
                    .withUrl(`${this.baseUrl}/realtime`, {
                        accessTokenFactory: () => response.idToken
                    })
                    .withAutomaticReconnect()
                    .configureLogging(environment.production ? signalR.LogLevel.Error : signalR.LogLevel.Debug)
                    .build();

                await this.connection.start();
            } catch (error) {
                throw new Error(`Failed to acquire token silently: ${error}`);
            }
        }

        return this.connection;
    }

    public async stopConnection(): Promise<void> {
        this.connection?.stop();
    }

    /**
     * Get the initiated SignalR Hub connection
     */
    public get hubConnection(): signalR.HubConnection | undefined {
        return this.connection;
    }
}