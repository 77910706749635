import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthConfigModule } from "./auth-config.module";
import { ErrorHandlerService } from "./core";
import { CoreModule } from "@stagescherm/core/core.module";
import { HomeComponent } from "./home/home.component";
import { SharedModule } from "./shared/shared.module";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MsalRedirectComponent } from "@azure/msal-angular";
import { MessageService } from "primeng/api";
import { ApplicationSettingsService } from "./core/services/application-settings/application-settings.service";
import { appInitializerFactory } from "./app-initializer-factory";

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    AuthConfigModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    SharedModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [ApplicationSettingsService],
      multi: true,
    },
    MessageService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}