import { Dictionary } from "./dictionary";

export interface ErrorResponse {
    message: string,
    correlationId: string,
    errors: CustomError[];
}

export interface CustomError extends Error {
    code: string;
    parameters: Dictionary<object>;
}

/**
 *  Check if the error is of type ErrorResponse
 * @param error Any error
 * @returns True if the error is of type ErrorResponse
 */
export function isErrorReponseType(error: any): error is ErrorResponse {
    return error.message 
    && error.correlationId 
    && error.errors !== undefined 
    && error.errors instanceof Array 
    && isCustomErrorType(error.errors[0]);
}

/**
 * Check if the error is of type CustomError
 * @param error any error
 * @returns True if the error is of type CustomError 
 */
export function isCustomErrorType(error: any): error is CustomError {
    return error.code !== undefined && error.parameters !== undefined;
}