  <div class="app-grid">
    <div class="app-header flex justify-content-between align-content-center">
      <a [routerLink]="['']"  class="home-link text-white" >
        <i class="pi pi-home"></i>
      </a>
      <div class="app-header-links flex align-items-baseline justify-content-center gap-2">
        <span class="user-welcome mr-3" *ngIf="loginDisplay">
          Welkom {{ username }}
        </span>
        <a [routerLink]="['contact']" class="text-white hover:underline">Contact</a>
        <a [routerLink]="['faq']"  class="text-white hover:underline">FAQ</a>
        <button pButton *ngIf="!loginDisplay" class="p-button-lg p-button-text text-white pl-0 hover:underline" (click)="login()" label="Inloggen"></button>
        <button pButton *ngIf="loginDisplay" class="p-button-lg p-button-text text-white pl-0 hover:underline" (click)="logout()" label="Uitloggen"></button>
      </div>
    </div>
    <div class="app-content">
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
  </div>
<p-toast position="top-right"
         key="error">
  <ng-template let-message pTemplate="message">
    <div class="flex-1">
      <div>
        <div class="p-toast-summary">{{message.summary}}</div>
        <div class="p-toast-detail">{{message.detail}}</div>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast position="top-right"></p-toast>
