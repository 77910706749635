import { HttpBackend, HttpClient } from "@angular/common/http";
import { StageschermService } from "./stagescherm.service";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class StageschermBackendService extends StageschermService {

    constructor(
        private backendHttpClient: HttpBackend) {
        super(new HttpClient(backendHttpClient));
    }
}