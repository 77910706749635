import { inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { APP_CONFIG } from '../app.config';

export function getApiUrl(services?: string): string {
    const appSettings = inject(APP_CONFIG)
    
    if (environment.production) {
       return `${appSettings.ApiEndpoint}/${services}/${appSettings.Values.ApiVersion}`;
      } else {
        return `${appSettings.ApiEndpoint}/${appSettings.Values.ApiVersion}`;
      }
}
