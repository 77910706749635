import { Injectable, WritableSignal, signal } from "@angular/core";
import { lastValueFrom } from "rxjs";

import * as _ from "lodash";
import { ApplicationSettings, hasAllDefaultSettings } from "../../../models/application-setting";
import { StageschermBackendService } from "../../../services/stagescherm.backend.service";

@Injectable({
  providedIn: 'root'
})
export class ApplicationSettingsService {
  private applicationSetting: ApplicationSettings | undefined;

  public settingsSignal: WritableSignal<ApplicationSettings | null> =
    signal<ApplicationSettings | null>(null, { equal: _.isEqual });

  public FeatureIsEnabled(setting: string): boolean {
    if (!this.settingsLoaded)
      this.loadApplicationSettings();

    const feature = this.applicationSetting![setting as keyof ApplicationSettings];
    if (feature === undefined)
      console.error(`Setting ${setting} not found`);

    return feature;
  }

  public get getSettings(): ApplicationSettings | undefined {
    return this.applicationSetting;
  }

  private settingsLoaded: boolean = false;

  constructor(
    private readonly service: StageschermBackendService,
  ) { }

  /**
   * Load the application settings from the backend and set the applicationSetting property.
   */
  public async loadApplicationSettings(): Promise<void> {
    try {
      this.applicationSetting = await lastValueFrom(
        this.service.get<ApplicationSettings>("application/applicationsettings")
      );

      if (!hasAllDefaultSettings(this.applicationSetting)) {
        console.error("Not all default settings are present in the application settings");
      }

      this.settingsSignal.set(this.applicationSetting);
      this.settingsLoaded = true;
    } catch (error) {
      console.error(error);
    }
  }
}
