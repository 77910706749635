<app-horizontal-page>
    <app-horizontal-page-menu>
        <a [routerLink]="['/stages']" class="horizontal-page-menu-item" *appAuthorized>
            <h1>Stages</h1>
            <p class="short-description">Beheer uw stages</p>
        </a>
        <a [routerLink]="['/leerbedrijf']" class="horizontal-page-menu-item" >
            <h1>Leerbedrijf-ID zoeken</h1>
            <p class="short-description">Zoek naar unieke leerbedrijf-ID's in het register van SBB</p>
        </a>
        <a [routerLink]="['/leerbedrijf/voorstellen']" class="horizontal-page-menu-item" *appAuthorized>
            <h1>Terugkoppeling</h1>
            <p class="short-description">Bekijk de status van aangemelde organisaties</p>
        </a>
    </app-horizontal-page-menu>
    <app-horizontal-page-content>
        <div *ngIf="!isAuthenticated; else notAuthenticated">
            <p>
                Welkom bij het Stagefonds-aanleverportaal. Log in om te starten met het aanleveren van stagegegevens.
            </p>
            <p>
                Bent u op zoek naar het ID van een leerbedrijf? Dan hoeft u niet eerst in te loggen. Druk op ‘Leerbedrijf-ID zoeken’ hierboven en start direct met zoeken.
            </p>
        </div>
        <ng-template #notAuthenticated>
            <p>Welkom. {{username}}</p>
            <p> 
                Op dit portaal kunt u stagegegevens aanleveren, leerbedrijf-ID’s zoeken en eventuele terugkoppeling op organisatievoorstellen bekijken. Instructies voor de verschillende handelingen kunt u terugvinden in de FAQ op deze website.
            </p>
        </ng-template>
    </app-horizontal-page-content>
</app-horizontal-page>