import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { BaseComponent } from '@stagescherm/core/base.component';
import { filter, takeUntil } from 'rxjs';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit  {
  public isAuthenticated = false;
  public username: string | undefined;

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService
  ) { 
    super();
  }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$.pipe(
      filter((eventType: InteractionStatus) => eventType === InteractionStatus.None),
      takeUntil(this.takeUntil$)
    ).subscribe(() => {

      this.isAuthenticated = this.authService.instance.getActiveAccount() != null;
    });
  }
}
