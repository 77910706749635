import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { getApiUrl } from './url-helper';

@Injectable({
  providedIn: 'root',
})
export class StageschermService {
  private readonly _serviceEndpoint = 'stagescherm';
  private readonly _baseUrl: string;
  constructor(
    private httpClient: HttpClient
  ) {
    this._baseUrl = getApiUrl(this._serviceEndpoint);
  }

  public get<T>(
    url: string,
    options?: {
      headers?: HttpHeaders | { [header: string]: string };
      params?: HttpParams;
    }
  ): Observable<T> {
    return this.httpClient.get<T>(`${this._baseUrl}/${url}`, {
      headers: options?.headers,
      params: options?.params,
      responseType: 'json',
    });
  }

  public getBlob(
    url: string,
    options?: {
      headers?: HttpHeaders | { [header: string]: string };
      params?: HttpParams;
    }
  ): Observable<Blob> {
    return this.httpClient.get(`${this._baseUrl}/${url}`, {
      headers: options?.headers,
      params: options?.params,
      responseType: 'blob',
    });
  }

  public post<T>(
    url: string,
    body: any,
    options?: {
      headers?: HttpHeaders | { [header: string]: string };
      params?: HttpParams;
    }
  ): Observable<T> {
    return this.httpClient.post<T>(
      `${this._baseUrl}/${url}`,
      body,
      options
    );
  }
}
