import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-menu-item',
  templateUrl: './page-menu-item.component.html',
  styleUrls: ['./page-menu-item.component.scss']
})
export class PageMenuItemComponent {
  @Input() title!: string;
  @Input() urlDescription: string = "";
  @Input() authorized: boolean = false;
}
