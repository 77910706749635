import { canMatchSettingsGuard } from '@stagescherm/core/Guards';
import { InternalServerErrorComponent } from './core/components/internal-server-error/internal-server-error.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { HomeComponent } from './home/home.component';
import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'stages',
    loadChildren: () =>
      import('./features/stages/stages.module').then((m) => m.StagesModule),
    canLoad: [() => inject(MsalGuard)],
    canMatch: [canMatchSettingsGuard('stageAanlevering')],
    canActivate: [MsalGuard]
  },
  {
    path: 'leerbedrijf',
    loadChildren: () =>
      import('./features/training-company/training-company.module').then((m) => m.LeerbedrijfModule)
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./features/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./features/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: '500',
    component: InternalServerErrorComponent,
    data: {
      title: 'Internal server error',
    },
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      title: 'Not found',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
