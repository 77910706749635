import { IAppConfig } from './app.config';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
  } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

function loggerCallback(logLevel: LogLevel, message: string) {
    switch (logLevel) {
        case LogLevel.Error:
            console.error(message);
            return;
        case LogLevel.Info:
            console.info(message);
            return;
        case LogLevel.Verbose:
            console.debug(message);
            return;
        case LogLevel.Warning:
            console.warn(message);
            return;
        case LogLevel.Trace:
            console.trace(message);
            return;
        default:
            console.log(message);
            return;
    }
  }

export function MSALInstanceFactory(config: IAppConfig): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: config.AzureAdB2C.ClientId,
      authority: config.AzureAdB2C.Authority,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: '/',
      knownAuthorities: [config.AzureAdB2C.AuthorityDomain],
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}