import { inject } from "@angular/core";
import { CanMatchFn, Route, Router, UrlSegment } from "@angular/router";
import { ApplicationSettingsService } from "@stagescherm/core/services/application-settings/application-settings.service";

/**
 * Returns a function that checks if the feature is configured in the application settings
 * @param feature the feature to check
 * @returns true if the feature is configured in the application settings else false
 */
export function canMatchSettingsGuard(feature: string): CanMatchFn {
    return (route: Route, segments: UrlSegment[]) => {
        const appSettingsService = inject(ApplicationSettingsService);
        const appRouter = inject(Router);
        if (!appSettingsService.getSettings || appSettingsService.getSettings[feature] === undefined) {
            appRouter.navigate(["/invalid-application-settings"]);
            return false;
        }
        return true;
    };
}