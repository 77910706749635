import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { APP_CONFIG, IAppConfig } from './app/app.config';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Here we read all settings from the config file. In this way all settings are available when bootstrapping the app.
// If we used the APP_INITIALIZER these settings would not be available
fetch(`assets/configuration/configuration.json`)
.then((response) => {
  if(response.ok) {
    return response.json() as Promise<IAppConfig>;
  }
  throw new Error('Config not found')
})
.then(async (response: IAppConfig) => {
  if (environment.production) {
    enableProdMode();
  }

  const appConfigProvider = {
    provide: APP_CONFIG,
    useValue: response
  };

  platformBrowserDynamic([appConfigProvider]).bootstrapModule(AppModule)
    .catch(err => console.error(err));
})
.catch((err) => console.error(err))
