import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from './components/tooltip';

const importExport = [
    AutoCompleteModule,
    ButtonModule,
    BreadcrumbModule,
    CardModule,
    CheckboxModule,
    DataViewModule,
    DialogModule,
    DropdownModule,
    FileUploadModule,
    FormsModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    MessageModule,
    MessagesModule,
    OverlayPanelModule,
    ToastModule,
    PaginatorModule,
    PanelModule,
    ProgressBarModule,
    TagModule,
    TooltipModule
]

@NgModule({
    declarations: [],
    imports: [ ...importExport],
    exports: [ ...importExport],

})
export class PrimeNgModule{}