import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Set loading state
   * @param loading True or false 
   */
  public isLoading(loading: boolean): void {
    this.isLoading$.next(loading);
  }
}
