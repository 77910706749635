import { ApplicationSettingsService } from "./core/services/application-settings/application-settings.service";

/**
 * Factory function for Angulars APP_INITIALIZE proces
 * @param appSettingsService The application settings services
 * @returns Promise when application is correctly initialized
 */
export function appInitializerFactory(appSettingsService: ApplicationSettingsService): () => Promise<void> {
    return async () => {
        try {
            await appSettingsService.loadApplicationSettings();

            if (appSettingsService.getSettings === undefined) {
                throw new Error("Application settings not found");
            }

            if (appSettingsService.getSettings.applicatieGesloten === true) {
                window.location.assign("/application-closed.html");
            }

            return Promise.resolve();
        } catch (error) {
            console.error(error);
            window.location.assign("/application-settings-not-found.html");
        }
    }
};