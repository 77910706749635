<div class="horizontal-page">
  <div class="horizontal-page-header">
    <ng-content select="app-horizontal-page-header"></ng-content>
  </div>
  <div class="horizontal-page-menu">
    <p-progressBar *ngIf="isLoading | async" mode="indeterminate" [style]="{ 'height': '6px', 'inset': '60% 0', 'border-radius': '0px', 'margin-top': '-6px'}" ></p-progressBar>
    <ng-content select="app-horizontal-page-menu">
    </ng-content>
  </div>
  <div class="horizontal-page-breadcrumbs">
    <p-breadcrumb class="horizontal-page-margin-left" [home]="home" [model]="menuItems" />
  </div>
  <div class="horizontal-page-content">
    <ng-content select="app-horizontal-page-content"></ng-content>
  </div>
</div>